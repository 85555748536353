import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { Widget } from '@ckeditor/ckeditor5-widget';
import { toWidget } from '@ckeditor/ckeditor5-widget';
import { toWidgetEditable } from '@ckeditor/ckeditor5-widget';

export default class SectionEditing extends Plugin {
	static get requires() {
		return [Widget];
	}

	init() {
		this._defineSchema();
		this._defineConverters();
		this.editor.commands.add('insertSection', new InsertSectionCommand(this.editor));
	}

	_defineSchema() {
		const schema = this.editor.model.schema;
		schema.register('section', {
			// Behaves like a self-contained object (e.g. an image).
			isObject: true,
			isBlock: true,
			allowAttributes:[ 'class','style'],
			allowContentOf: '$root',
			// Allow in places where other blocks are allowed (e.g. directly in the root).
			allowWhere: '$block'
		});
		schema.addChildCheck((context, childDefinition) => {
			if (childDefinition.name == 'section' && Array.from( context.getNames() ).includes( 'section' )) {
				return false;
			}
		});
	}

	_defineConverters() {
		const conversion = this.editor.conversion;
		// <reportFormatBox> converters
		conversion.for('upcast').elementToElement({
			model: 'section',
			view: {
				name: 'section',
				classes: 'section'
			},
			model: ( viewImage, { writer } ) => writer.createElement( 'section', { style: viewImage.getAttribute( 'style' ) } )
		}).attributeToAttribute( {
				view: {
					name: 'section',
					key: 'style'
				},
				model: 'style'
			} );
		conversion.for('dataDowncast').elementToElement({
			model: 'section',
			view: (modelElement, { writer }) => {
				return writer.createContainerElement('section', { class: 'section', style: modelElement.getAttribute( 'style' ) });
			}
		});
		conversion.for('editingDowncast').elementToElement({
			model: 'section',
			view: (modelElement, { writer }) => {
				const section = writer.createContainerElement('section', { class: 'section',style: modelElement.getAttribute( 'style' ) });

				return toWidgetEditable(section, writer, { label: 'section widget' });
			}
		});
		conversion.for( 'downcast' )
			.add( modelToViewAttributeConverterForSection( 'style' ) );
	}
}

class InsertSectionCommand extends Command {
	execute() {
		this.editor.model.change(writer => {
			// Insert <reportFormatBox>*</reportFormatBox> at the current selection position
			// in a way that will result in creating a valid model structure.
			this.editor.model.insertContent(createSection(writer));
		});
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'section');

		this.isEnabled = allowedIn !== null;
	}
}

function createSection(writer) {
	const section = writer.createElement('section');
	writer.appendElement('paragraph', section); 
	return section;
}

 export function modelToViewAttributeConverterForSection( attributeKey ) {
	return dispatcher => {		
		dispatcher.on( `attribute:${ attributeKey }:section`, converter );
	};
	function converter( evt, data, conversionApi ) {
		if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
			return;
		}
		const viewWriter = conversionApi.writer;
		const figure = conversionApi.mapper.toViewElement( data.item );
		const img = figure;
		if ( data.attributeNewValue !== null ) {
			viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, img );
		} else {
			viewWriter.removeAttribute( data.attributeKey, img );
		}
	}
}

class Section extends Plugin {
	static get requires() {
		return [SectionEditing];
	}
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'SectionEditing';
	}
}
