import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';

export default class ReportFormatBoxEditing extends Plugin {
	static get requires() {
		return [Widget];
	}

	init() {
		this._defineSchema();
		this._defineConverters();
		this.editor.commands.add('insertReportFormatBox', new InsertReportFormatBoxCommand(this.editor));
	}

	_defineSchema() {
		const schema = this.editor.model.schema;
		schema.register('reportFormatBox', {
			isObject: true,
			allowWhere: '$block'
		});

		schema.register('reportFormatBoxHeader', {
			isLimit: true,
			allowIn: 'reportFormatBox',
			allowContentOf: '$root'
		});

		schema.register('reportFormatBoxContent', {
			isLimit: true,
			allowIn: 'reportFormatBox',
			allowContentOf: '$root'
		});

		schema.register('reportFormatBoxFooter', {
			isLimit: true,
			allowIn: 'reportFormatBox',
			allowContentOf: '$root'
		});

		schema.addChildCheck((context, childDefinition) => {
			if ((context.endsWith('reportFormatBoxContent') || context.endsWith('reportFormatBoxHeader') || context.endsWith('reportFormatBoxFooter')) && childDefinition.name == 'reportFormatBox') {
				return false;
			}
		});
	}

	_defineConverters() {
		const conversion = this.editor.conversion;
		// <reportFormatBox> converters
		conversion.for('upcast').elementToElement({
			model: 'reportFormatBox',
			view: {
				name: 'section',
				classes: 'report-format-box'
			}
		});
		conversion.for('dataDowncast').elementToElement({
			model: 'reportFormatBox',
			view: (modelElement, { writer }) => {
				return writer.createContainerElement('section', { class: 'report-format-box' });
			}
		});
		conversion.for('editingDowncast').elementToElement({
			model: 'reportFormatBox',
			view: (modelElement, { writer }) => {
				const section = writer.createContainerElement('section', { class: 'report-format-box' });

				return toWidget(section, writer, { label: 'report format box widget' });
			}
		});
		// <reportFormatBoxTitle> converters
		conversion.for('upcast').elementToElement({
			model: 'reportFormatBoxHeader',
			view: {
				name: 'div',
				classes: 'report-format-header'
			}
		});
		conversion.for('dataDowncast').elementToElement({
			model: 'reportFormatBoxHeader',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				return writer.createEditableElement('div', { class: 'report-format-header' });
			}
		});
		conversion.for('editingDowncast').elementToElement({
			model: 'reportFormatBoxHeader',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				const header = writer.createEditableElement('div', { class: 'report-format-header' });
				return toWidgetEditable(header, writer);
			}
		});
		// <reportFormatBoxDescription> converters
		conversion.for('upcast').elementToElement({
			model: 'reportFormatBoxContent',
			view: {
				name: 'div',
				classes: 'report-format-content'
			}
		});
		conversion.for('dataDowncast').elementToElement({
			model: 'reportFormatBoxContent',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				return writer.createEditableElement('div', { class: 'report-format-content' });
			}
		});
		conversion.for('editingDowncast').elementToElement({
			model: 'reportFormatBoxContent',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				const div = writer.createEditableElement('div', { class: 'report-format-content' });
				return toWidgetEditable(div, writer);
			}
		});
		// <reportFormatBoxFooter> converters
		conversion.for('upcast').elementToElement({
			model: 'reportFormatBoxFooter',
			view: {
				name: 'div',
				classes: 'report-format-footer'
			}
		});
		conversion.for('dataDowncast').elementToElement({
			model: 'reportFormatBoxFooter',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				return writer.createEditableElement('div', { class: 'report-format-footer' });
			}
		});
		conversion.for('editingDowncast').elementToElement({
			model: 'reportFormatBoxFooter',
			view: (modelElement, { writer }) => {
				// Note: You use a more specialized createEditableElement() method here.
				const footer = writer.createEditableElement('div', { class: 'report-format-footer' });
				return toWidgetEditable(footer, writer);
			}
		});
	}
}

class InsertReportFormatBoxCommand extends Command {
	execute() {
		this.editor.model.change(writer => {
			// Insert <reportFormatBox>*</reportFormatBox> at the current selection position
			// in a way that will result in creating a valid model structure.
			this.editor.model.insertContent(createReportFormatBox(writer));
		});
	}

	refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'reportFormatBox');
		this.isEnabled = allowedIn !== null;
	}
}

function createReportFormatBox(writer) {
	const reportFormatBox = writer.createElement('reportFormatBox');
	const reportFormatBoxTitle = writer.createElement('reportFormatBoxHeader');
	const reportFormatBoxContent = writer.createElement('reportFormatBoxContent');
	const reportFormatBoxFooter = writer.createElement('reportFormatBoxFooter');
	writer.append(reportFormatBoxTitle, reportFormatBox);
	writer.append(reportFormatBoxContent, reportFormatBox);
	writer.append(reportFormatBoxFooter, reportFormatBox);
	// There must be at least one paragraph for the description to be editable.
	// See https://github.com/ckeditor/ckeditor5/issues/1464.
	writer.appendElement('paragraph', reportFormatBoxContent);
	writer.appendElement('paragraph', reportFormatBoxTitle);
	writer.appendElement('paragraph', reportFormatBoxFooter);

	return reportFormatBox;
}

class ReportFormatBox extends Plugin {
	static get requires() {
		return [ReportFormatBoxEditing];
	}
	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'ReportFormatBoxEditing';
	}
}
